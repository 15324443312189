<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">                
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            ref="name"
                            v-model="unitForm.name" 
                            label="Nombre" 
                            outlined 
                            :rules="[() => !!unitForm.name || 'Es necesario ingresar un nombre']"
                            hide-details="auto"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>                
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            v-model="unitForm.description"
                            outlined
                            label="Descripción"
                            >
                        </v-textarea>
                    </v-col>
                </v-row>                   
            </template>                    
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn v-if="action == 'create'" dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                        <v-btn v-if="action == 'update'" dark color="success" block height="40" @click="handleUpdate">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import { mapActions} from 'vuex';

export default {
    name: 'CreateUpdate',
    data() {
        return {
            drawer: false,
            titleForm: null,
            action: null,
            unitForm: {
                id: null,
                name: null,
                description: null
            }
        }
    },
    methods: {
        ...mapActions('units', [
            'store',
            'update'
        ]),
        async showForm(title, action, unit = null) {
            await this.clearData();
            this.titleForm = title
            this.action = action
            if (unit) {
                this.setUnit(unit)
            }
            this.drawer = !this.drawer
        },
        clearData() {
            this.titleForm = null
            this.action = null
            this.unitForm.id = null
            this.unitForm.name = null
            this.unitForm.description = null
            this.clearErrors();
        },
        setUnit(unit) {
            this.unitForm.id = unit.id
            this.unitForm.name = unit.name
            this.unitForm.description = unit.description
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        handleCancel() {
            this.closeForm()
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.unitForm).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.unitForm[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.unitForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
        async handleStore() {
            if(!this.existErrors()){
                await this.store(this.unitForm)
                this.closeForm()
            }            
        },
        async handleUpdate() {
            if(!this.existErrors()){
                await this.update(this.unitForm)
                this.closeForm()
            }            
        },
    }
}
</script>

<style scoped>

</style>