<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Unidades de medida</span>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn v-if="this.$hasPermision('unidades.create')"
                 dark
                 color="#023145"
                 width="140"
                 class="float-right" @click="handleShowCreate">
            Nuevo
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="pt-1">
          <v-text-field
              v-model="search_unit"
              label="Buscar"
              hide-details
              prepend-inner-icon="mdi-magnify"
              solo
              @keydown="handleInputSearchByEnter">
          </v-text-field>
        </v-col>
        <v-col cols="6" class="pt-1">
          <v-btn class="w-btn-search" height="40" @click="handleSearch">
            Buscar
          </v-btn>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn v-if="this.$hasPermision('unidades.filters')"
                 dark color="#303197"
                 outlined
                 width="140"
                 class="float-right"
                 @click="handleShowFilters">
            <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
            Ver Filtros
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
                v-model="selectedRows"
                :headers="headers"
                :items="units"
                :items-per-page="10"
                class="elevation-1 w-table"
                height="57vh"
                show-select
                :single-select="true"
                @item-selected="handleSelectItem"
                :loading="loading"
                :options.sync="options"
                :server-items-length="paginated.total"
                loading-text="Loading... Please wait"
            >

              <template v-slot:top>
                                <span class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todas las unidades de medida
                                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.name="{ item }">
                <span class="font-weight-medium">{{ item.name }}</span>
                <span v-if="item.active" class="activo">
                                    <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                                    activo
                                </span>
                <span v-else class="inactivo">
                                    <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                                    inactivo
                                </span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <div class="text-center">
      <v-bottom-sheet v-model="show_options_crud" :hide-overlay="true" persistent :retain-focus="false">
        <v-sheet class="text-center" height="85px">
          <div class="py-3">
            <v-btn v-if="this.$hasPermision('unidades.edit')" class="mt-3" dark color="blue" outlined
                   @click="handleEdit">
              <v-icon class="mr-2" size="16">fas fa-pen</v-icon>
              Editar
            </v-btn>
            <v-btn v-if="show_btn_active && this.$hasPermision('unidades.change_status')" class="ml-4 mt-3" dark
                   color="green" outlined @click="handleChangeStatus">
              <v-icon class="mr-2" size="16">far fa-check-circle</v-icon>
              Activar
            </v-btn>
            <v-btn v-if="!show_btn_active && this.$hasPermision('unidades.change_status')" class="ml-4 mt-3" dark
                   color="amber" outlined @click="handleChangeStatus">
              <v-icon class="mr-2" size="16">fas fa-minus-circle</v-icon>
              Inactivar
            </v-btn>
            <v-btn v-if="this.$hasPermision('unidades.destroy')" class="ml-4 mt-3" dark color="red" outlined
                   @click="handleDelete">
              <v-icon class="mr-2" size="16">fas fa-times-circle</v-icon>
              Eliminar
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <create-update ref="createUpdate"></create-update>
    <FiltersPaginated ref="filters"></FiltersPaginated>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import ArrayTools from '../../../helpers/ArrayTools';
import CreateUpdate from '../../../components/units/CreateUpdate';
import FiltersPaginated from '../../../components/units/FiltersPaginated';

export default {
  name: 'Units',
  components: {
    CreateUpdate,
    FiltersPaginated,
    OfficeLogin
  },
  watch: {
    options: {
      handler() {
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        const search = this.search_unit
        this.loading = true
        if (this.filterPaginated.name != null || this.filterPaginated.description != null) {
          this.getUnits({
            paginated: true, page: this.pageSelected, itemsPerPage: this.perPage, name: this.filterPaginated.name,
            description: this.filterPaginated.description
          }).then(res => this.loading = false)
              .catch(err => this.loading = false)
        } else {
          if (search != null && search.trim().length > 0) {
            // this.perPage = -1
            this.getUnits({
              paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,
              name: search
            }).then(res => this.loading = false)
                .catch(err => this.loading = false)
          } else {
            this.getUnits({paginated: true, page: this.pageSelected, itemsPerPage: this.perPage})
                .then(res => this.loading = false)
                .catch(err => this.loading = false)
          }
        }
      },
      deep: true,
    }
  },
  data() {
    return {
      itemsBreadcrumbs: [
        {
          text: 'Registros',
          disabled: true,
          href: '',
        }
      ],
      search_unit: null,
      drawer: false,
      show_options_crud: false,
      show_btn_active: false,
      selectedRows: [],
      headers: [
        {text: 'NOMBRE', value: 'name'},
        {text: 'DESCRIPCIÓN', value: 'description'},
      ],
      loading: false,
      dataTable: {page: 1},
      options: {},
    }
  },
  computed: {
    ...mapState('units', [
      'units',
      'paginated',
      'filterPaginated'
    ])
  },
  mounted() {
    this.changeFilterPaginated({name: null, description: null})
  },
  methods: {
    ...mapActions('units', [
      'getUnits',
      'change_status',
      'delete',
      'changeFilterPaginated'
    ]),
    async onPageChange(page) {
      this.loading = true
      await this.getUnits({page: page, paginated: true})
          .then(result => this.loading = false)
          .catch(errr => this.loading = false)
    },
    showOptionCrud() {
      this.show_options_crud = this.selectedRows.length > 0
      if (this.show_options_crud) {
        this.show_btn_active = !this.selectedRows[0].active
      }
    },
    handleSelectItem(selected) {
      if (selected.value) {
        this.selectedRows.push(selected.item)
      } else {
        let index_found = ArrayTools.getIndexById(this.selectedRows, selected.item.id)
        if (index_found > -1) {
          this.selectedRows.splice(index_found, 1)
        }
      }
      this.showOptionCrud()
    },
    handleShowCreate() {
      this.$refs.createUpdate.showForm('Nueva unidad de medida', 'create');
    },
    handleEdit() {
      let unit = this.selectedRows[0]
      this.selectedRows = []
      this.showOptionCrud()
      this.$refs.createUpdate.showForm('Modificar datos de unidad', 'update', unit);
    },
    async handleChangeStatus() {
      let unit = this.selectedRows[0]
      this.selectedRows = []
      this.showOptionCrud()
      await this.change_status(unit)
    },
    async handleDelete() {
      let unit = this.selectedRows[0]
      await this.delete(unit)
      this.selectedRows = []
      this.showOptionCrud()
    },
    handleShowFilters() {
      this.$refs.filters.showForm('Filtrar datos de unidades');
    },
    handleInputSearchByEnter(KeyboardEvent) {
      if (KeyboardEvent.code == 'Enter') {
        this.handleSearch();
      }
    },
    async handleSearch() {
      this.selectedRows = []
      this.showOptionCrud()
      this.loading = true
      let filters = {}
      this.changeFilterPaginated({name:null,description:null})
      this.dataTable.page = 1
      if (this.search_unit.trim().length > 0) {
        filters = {
          name: this.search_unit,
        }
      } else {
        filters = {
          paginated: true
        }
      }
      await this.getUnits(filters)
          .then(result => {
            this.loading = false
          })
          .catch(error => {
                this.loading = false
              }
          )
    },
  },
  created() {
    // this.loading = true
    this.dataTable.page = 1
    // this.getUnits({paginated:true}).then(result => {
    //   this.loading = false
    // })
    //     .catch(error => {
    //       this.loading = false
    //     });
  }
}
</script>

<style scoped>

</style>